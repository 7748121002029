<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:00:28
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-05-24 17:26:40
 * @FilePath: \dalian\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <HeaderContent @change="changeHandler"/>
    <div class="content">
      <component :is="componentId"></component>
      <FooterContent/>
    </div>
  </div>
</template>

<script>
import HeaderContent from './components/HeaderContent.vue'
import TabContent1 from './components/TabContent1.vue'
import TabContent2 from './components/TabContent2.vue'
import TabContent3 from './components/TabContent3.vue'
import TabContent4 from './components/TabContent4.vue'
import FooterContent from './components/FooterContent.vue'

export default {
  name: 'App',
  components: {
    HeaderContent,
    TabContent1,
    TabContent2,
    TabContent3,
    TabContent4,
    FooterContent
  },
  data(){
    return {
      componentId: TabContent1
    }
  },
  methods:{
    changeHandler(componentId){
      this.componentId = componentId
    }
  }
}
</script>

<style>
body,html{
  height: 100%;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
  background: white;
  height: 100%;
  display: flex;
  min-width: 1300px;
  flex-direction: column;
}
.content{
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
::-webkit-scrollbar {

  display: none; /* Chrome Safari */

}
</style>
