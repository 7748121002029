<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-05-24 17:47:25
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner4.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png" class="tab4-1">
        <div>多呗科技信息技术有限公司成立于2014年，作为专注于服务政府、税务、企业的数字化交易服务商、数字化园区运营服务商，公司现有员工约230人，在全国12个省市落地了数字产业园区，平台服务小微商户超过150万。</div>
        <img src="../assets/tab4/2.png" class="tab4-2">
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .tab4-1{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }
 .tab4-2{
    height: 343px;
    width: 1258px;
    margin: 103px auto 40px auto;
 }
</style>
