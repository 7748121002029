<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-05-24 17:42:58
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner3.png" class="banner">
    <div class="content">
      <div class="tab-box">
        <div class="active">行业前沿</div>
        <div>政策法规</div>
        <div>产业洞察</div>
        <div>公司动态</div>
      </div>
      <div class="tab-content">
        <div class="tab-content-item">
          <img src="../assets/tab3/1.png">
          <div class="left">
            <div class="title">按月、按季申报纳税人如何适用新规?</div>
            <div class="desc">按照《国家税务总局关于开展2021年“我为纳税人缴费人办实事医便民办税春风行动”的意见》(税总发(2021] 14号) 要，国家税务总局近日发布《关于简并税费申报有关事项的公告》 (国家税务总局公告2021年第9号)，明确自2021年5月1日起，在海南、陕西、大连、厦门4省市…</div>
            <div class="desc" style="margin-top:25px;">发布日期 : 2023-05-15</div>
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/2.png">
          <div class="left">
            <div class="title">国务院常务会确定了进一步支持灵活就业的措施</div>
            <div class="desc">李克强主持召开国务院常务会议决定将部分减负稳岗扩就业政策期限延长到今年底 确定进一步支持灵活就业的措施部署加强对受疫情持续影行业企业的金融支持通过《建设工程抗要管理条例(草案)》国务院总理李克强5月12日主持召开国务院常务会议，决定将部分减负稳…</div>
            <div class="desc" style="margin-top:25px;">发布日期 : 2023-04-26</div>
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/3.png">
          <div class="left">
            <div class="title">泓明智能运输，实现车辆轨迹可视化！</div>
            <div class="desc">5月11日市场监管总局发布《关于进一步规范电子商务经营者登记工作的通知(征求意见)》，《意见》要求对于自然人通过网络平台从事利用自己技能开展无须取得许可的便民劳务活动的、依托平台接受网约工作任务的、从事其他依法无需办理营业执照登记的经营活动的，如需办理…</div>
            <div class="desc" style="margin-top:25px;">发布日期 : 2023-04-21</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent3'
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 56px auto;
 }

 .tab-box{
  display: flex;
  gap: 50px;
 }
  .tab-box>.active{
    background: #EA5404;
  }
   .tab-box>div{
    flex: 1;
    height: 63px;
    background: #474747;
    border-radius: 10px;
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-content-item{
    display: flex;
    gap: 26px;
    margin-top: 90px;
  }
  .tab-content-item>img{
    width: 536px;
    height: 227px;
  }
  .tab-content-item>.left>.title{
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #111111;
    margin-bottom: 15px;
  }
  .tab-content-item>.left>.desc{
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 300;
    color: #111111;
    line-height: 30px;
    text-align: justify;
  }
</style>
